<template>
  <div id="app" class="myfs-portal">
    <the-overlay />
    <the-upgrade-popup v-if="isUpgradeToTotalAllowed" />
    <the-subscription-reminder-popup
      v-if="customerIsCompleteAndLoggedIn"
      :evaluate-show-on-mount="runReminderPopup"
    />
    <the-additional-service-activation-popup v-if="showActivationPoup" />
    <the-additional-service-activation-instructions-popup />
    <the-inactive-popup
      v-if="customerIsInactive"
      :inactive-show-on-mount="runInactivePopup"
    />

    <transition name="fade">
      <loading-view v-show="showLoading" class="loading-view" />
    </transition>
    <transition name="fade">
      <keep-alive>
        <router-multi-view v-show="!showLoading" v-if="!waitForRedirect" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CookieService from '@/services/CookieService'
import CartService from '@/services/CartService'
import PrerendererService from '@/services/PrerendererService'

const TheOverlay = () =>
  import(/* webpackChunkName: "views" */ '@/components/TheOverlay.vue')
const LoadingView = () =>
  import(/* webpackChunkName: "loading-view" */ '@/views/LoadingView.vue')
const TheUpgradePopup = () =>
  import(/* webpackChunkName: "views" */ '@/components/TheUpgradePopup.vue')
const TheSubscriptionReminderPopup = () =>
  import(
    /* webpackChunkName: "views" */ '@/components/TheSubscriptionReminderPopup.vue'
  )
const TheAdditionalServiceActivationPopup = () =>
  import(
    /* webpackChunkName: "views" */ '@/components/TheAdditionalServiceActivationPopup.vue'
  )
const TheAdditionalServiceActivationInstructionsPopup = () =>
  import(
    /* webpackChunkName: "views" */ '@/components/TheAdditionalServiceActivationInstructionsPopup.vue'
  )
const TheInactivePopup = () => {
  return import(
    /* webpackChunkName: "views" */ '@/components/TheInactivePopup.vue'
  )
}

/* eslint-disable */
import normalize from '@/assets/css/normalize.css'

import(/* webpackChunkName: "vendor" */
'@/assets/css/fonts.css').catch(err => {
  if (!global || typeof global.it !== 'function') {
    throw err
  }
})

/* eslint-enable */

const pageNameMap = {
  '/login': 'login',
  '/register': 'register',
  '/portal/order': 'orders',
  '/portal/contact': 'account',
  '/portal/contact/update': 'update',
  '/portal/delete-user/confirmation': 'delete-user-confirmation',
  '/portal/cancel_autorenew': 'cancel-autorenew',
  '/portal/coupon/renew': 'coupon-renewal'
}

export default {
  components: {
    LoadingView,
    TheOverlay,
    TheUpgradePopup,
    TheSubscriptionReminderPopup,
    TheAdditionalServiceActivationPopup,
    TheAdditionalServiceActivationInstructionsPopup,
    TheInactivePopup
  },
  data() {
    return {
      windowLoaded: false,
      cookiePollInterval: null,
      enteringToRoute: false,
      fsFataLoadedEventSent: false,
      runReminderPopup: false,
      analyticsEvents: [],
      routesEntered: 0,
      cartUpdates: 0,
      senseCartUpdates: 0,
      tagManagersInitialized: false,
      isInactive: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      counterTest: 0,
      runInactivePopup: false
    }
  },
  computed: {
    ...mapGetters({
      customerIsIncomplete: 'customer/isIncomplete',
      purchaseUrl: 'ui/purchaseUrl',
      safeUsedLicenses: 'customer/licenses/getSafeLicensesUsed',
      freedomeUsedLicenses: 'customer/licenses/getFreedomeLicensesUsed',
      keyUsedLicenses: 'customer/licenses/getKeyLicensesUsed',
      isSubUser: 'customer/contract/isSubUser',
      isIncomplete: 'customer/isIncomplete',
      isUpgradeAllowed: 'ui/isUpgradeAllowed',
      isSenseSubscription: 'customer/contract/isSenseSubscription',
      isRenewCart: 'ui/isRenewCart',
      isBuyNowCart: 'ui/isBuyNowCart',
      isBuyMoreCart: 'ui/isBuyMoreCart',
      isPrepaid: 'customer/contract/isPrepaid',
      productNames: 'ui/productNames',
      isCartLoaded: 'ui/isCartLoaded'
    }),
    ...mapState({
      appDataLoaded: state => state.customer.remoteDataLoaded,
      customerApiCallsAmount: state => state.ui.customerApiCallsAmount,
      geoRegion: state => state.customer.geoRegion,
      maintenanceSafeAvenue: state => state.customer.maintenanceSafeAvenue,
      isUpgradeToTotalAllowed: state =>
        state.customer.contract.isUpgradeToTotalAllowed,
      customerIsLoggedIn: state => state.customer.isLoggedIn,
      promotionId: state => state.customer.promotionId,
      subCategory: state => state.customer.subCategory,
      safeTotalLicenses: state => state.customer.licenses.safeTotalLicenses,
      safeAvailableLicenses: state =>
        state.customer.licenses.safeAvailableLicenses,
      freedomeTotalLicenses: state =>
        state.customer.licenses.freedomeTotalLicenses,
      freedomeAvailableLicenses: state =>
        state.customer.licenses.freedomeAvailableLicenses,
      keyTotalLicenses: state => state.customer.licenses.keyTotalLicenses,
      keyAvailableLicenses: state =>
        state.customer.licenses.keyAvailableLicenses,
      contractType: state => state.customer.contract.type,
      isAutomaticSubscription: state =>
        state.customer.contract.isAutomaticSubscription,
      currentLocale: state => state.ui.currentLocale,
      directCartUrl: state => state.ui.cart.direct_to_cart,
      cart: state => state.ui.cart,
      waitForRedirect: state => state.ui.waitForRedirect,
      additionalServices: state => state.customer.additionalServices,
      loadedLanguages: state => state.ui.loadedLanguages,
      sensePurchaseUrl: state => state.ui.sensePurchaseUrl,
      customerStatus: state => state.customer.status,
      isInactivePopupOpen: state => state.ui.isInactivePopupOpen
    }),
    customerIsIncompleteAndLoggedIn() {
      return (
        this.customerIsLoggedIn &&
        this.appDataLoaded &&
        this.customerIsIncomplete
      )
    },
    customerIsCompleteAndLoggedIn() {
      return (
        this.customerIsLoggedIn &&
        this.appDataLoaded &&
        !this.customerIsIncomplete
      )
    },
    customerIsInactive() {
      return this.isInactive
    },
    showActivationPoup() {
      return (
        this.customerIsCompleteAndLoggedIn && this.$route.query.activate_service
      )
    },
    showLoading() {
      return !this.appDataLoaded || this.waitForRedirect
    }
  },
  watch: {
    customerStatus(newValue, oldValue) {
      if (oldValue === 'incomplete' && newValue === 'active') {
        this.$root.$emit('customer-became-complete')
      }
    },
    customerApiCallsAmount() {
      this.$root.$emit('customer-api-call-finished')
    },
    cart() {
      this.cartUpdates++
      this.$root.$emit('cart-updated', this.cartUpdates)
    },
    sensePurchaseUrl(newValue, oldValue) {
      if (newValue === oldValue) {
        return
      }

      this.senseCartUpdates++
      this.$root.$emit('sense-purchase-url-updated', newValue)
    }
  },
  created() {
    if (PrerendererService.isPrerendererBrowser()) {
      return // Don't load launch on render
    }

    this.initializeFsData()
  },
  beforeMount() {
    const cookieFound = this.setLanguageFromCookies()

    if (!cookieFound) {
      this.setLanguageByNavigator()
    }
  },
  mounted() {
    this.clearPreviousLicenseCookies()
    window.addEventListener('load', this.onWindowLoad)
    window.addEventListener('popstate', this.onPopState)
    this.$root.$on('cookie_updated', this.updateLicenseDataFromCookies)
    this.cookiePollInterval = this.launchCookiePoll()
    this.$root.$on('just-logged-in', this.addFsDataLoginEvent)
    this.$root.$on('all-product-iframes-loaded', this.activateActivityTracker)
    this.$root.$on('customer-api-call-finished', this.runAfterDataLoaded)
    this.$root.$on('route-entered', this.updateFsDataIfAppDataLoaded)
    this.$root.$on('route-entered', this.updateHrefLangPaths)
    this.$root.$on(
      'product-view-route-updated',
      this.updateFsDataIfAppDataLoaded
    )
    this.$root.$on('route-entered', this.runIfNotLoggedIn)
    this.$root.$on('update-fsdata', this.updateFsDataForCurrentLocation)
    this.$root.$on(
      'customer-became-complete',
      this.updateFsDataForCurrentLocation
    )
    this.$root.$on('update-fsdata-pagename', this.updateFsDataPageName)
    this.$root.$on('all-product-iframes-loaded', this.updateFsDataLicenses)
    this.$root.$on('current-locale-updated', this.updateHtmlLang)
    this.$root.$on('current-locale-updated', this.updateHtmlTitle)
    this.$root.$on('current-locale-updated', this.updatePageMetaDescriptions)
    this.$root.$on('fsdata-loaded', this.triggerAnalyticsEvents)
    this.$root.$on('route-entered', this.countRoutesAndEmitFirst)
    this.$root.$on('first-page-view', this.runAfterRouting)
    this.$root.$on('customer-api-call-finished', this.runAfterCartLoaded)
    this.$root.$on('cart-updated', this.runAfterCartLoaded)
    this.$root.$on(
      'sense-purchase-url-updated',
      this.navigateToSenseCartByNextIfCartLoaded
    )
  },
  beforeDestroy() {
    if (this.cookiePollInterval) {
      window.clearInterval(this.cookiePollInterval)
    }

    this.$root.$off('cookie_updated', this.updateLicenseDataFromCookies)
    this.$root.$off('just-logged-in', this.addFsDataLoginEvent)
    this.$root.$off('customer-api-call-finished', this.runAfterDataLoaded)
    this.$root.$off('route-entered', this.updateFsDataIfAppDataLoaded)
    this.$root.$off('route-entered', this.updateHrefLangPaths)
    this.$root.$off(
      'product-view-route-updated',
      this.updateFsDataIfAppDataLoaded
    )
    this.$root.$off('route-entered', this.runIfNotLoggedIn)
    this.$root.$off('update-fsdata', this.updateFsDataForCurrentLocation)
    this.$root.$off(
      'customer-became-complete',
      this.updateFsDataForCurrentLocation
    )
    this.$root.$off('update-fsdata-pagename', this.updateFsDataPageName)
    this.$root.$off('all-product-iframes-loaded', this.updateFsDataLicenses)
    this.$root.$off('current-locale-updated', this.updateHtmlLang)
    this.$root.$off('current-locale-updated', this.updateHtmlTitle)
    this.$root.$off('current-locale-updated', this.updatePageMetaDescriptions)
    this.$root.$off('fsdata-loaded', this.triggerAnalyticsEvents)
    this.$root.$off('route-entered', this.countRoutesAndEmitFirst)
    this.$root.$off('first-page-view', this.runAfterRouting)
    this.$root.$off('cart-updated', this.runAfterCartLoaded)
    this.$root.$off(
      'sense-purchase-url-updated',
      this.navigateToSenseCartByNextIfCartLoaded
    )
    window.removeEventListener('mousemove', this.userActivityThrottler)
    window.removeEventListener('scroll', this.userActivityThrottler)
    window.removeEventListener('keydown', this.userActivityThrottler)
    window.removeEventListener('resize', this.userActivityThrottler)

    clearTimeout(this.userActivityTimeout)
    clearTimeout(this.userActivityThrottlerTimeout)
  },
  methods: {
    activateActivityTracker() {
      this.userActivityThrottler()
      window.addEventListener('mousemove', this.userActivityThrottler)
      window.addEventListener('scroll', this.userActivityThrottler)
      window.addEventListener('keydown', this.userActivityThrottler)
      window.addEventListener('resize', this.userActivityThrottler)
    },
    userActivityThrottler() {
      this.counterTest = this.counterTest + 1
      if (!this.isInactivePopupOpen) {
        this.isInactive = false
        this.resetUserActivityTimeout()
      }
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler()
        this.inactiveUserAction()
        this.logoutAfterUserInactive()
      }, 15 * 60 * 1000) // 15mins
    },
    inactiveUserAction() {
      this.isInactive = true
      this.runInactivePopup = true
    },
    logoutAfterUserInactive() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        const newLocation = this.$router.resolve('/logout')
        this.$router.push(newLocation.resolved.fullPath)
      }, 1 * 60 * 1000) // 1min
    },
    initializeTagManagers() {
      if (PrerendererService.isPrerendererBrowser()) {
        return // Don't load tag managers on render
      }

      // GTM script addition
      ;(function(w, d, s, l) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0]
        var j = d.createElement(s)
        j.async = true
        j.src = process.env.VUE_APP_GTM_SCRIPT_URL
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'fsData')
    },
    runAfterCartLoaded() {
      let isInternalRedirect = false
      if (this.cartUpdates === 1) {
        isInternalRedirect = this.navigateToNext()
        if (this.customerIsCompleteAndLoggedIn) {
          this.removeNextCookie()
        }
      }

      this.redirectToWelcomeIfCannotUpgrade()

      const routePath = this.$route.path
      if (
        this.appDataLoaded &&
        routePath === '/portal/product/upgrade' &&
        this.isCartLoaded
      ) {
        this.$store.commit('ui/SET_WAIT_FOR_REDIRECT', false)
        this.updateFsDataIfAppDataLoaded(routePath)
      }

      if (
        this.waitForRedirect &&
        (isInternalRedirect ||
          (this.customerIsLoggedIn && this.senseCartUpdates > 0))
      ) {
        this.$store.commit('ui/SET_WAIT_FOR_REDIRECT', false)
      }
    },
    redirectToWelcomeIfCannotUpgrade() {
      if (!this.customerIsCompleteAndLoggedIn) {
        return
      }
      if (
        this.$route.name === 'product-upgrade-view' &&
        !this.isUpgradeAllowed
      ) {
        let routePath = this.isSenseSubscription
          ? '/portal/order'
          : '/portal/welcome'
        this.$router.replace(routePath)
      }
    },
    runAfterRouting() {
      if (!this.tagManagersInitialized) {
        this.tagManagersInitialized = true
        this.initializeTagManagers()
      }
      this.saveNextCookie()
      this.changeAndSaveLanguageByURL()
    },
    countRoutesAndEmitFirst() {
      this.routesEntered++
      if (this.routesEntered === 1) {
        this.$root.$emit('first-page-view')
      }
    },
    changeAndSaveLanguageByURL() {
      let savedLocale = this.saveUrlLocaleToCookie()
      if (savedLocale) {
        this.setLanguage(savedLocale)
      }
    },
    updateFsDataForCurrentLocation() {
      this.updateFsDataIfAppDataLoaded(this.$route.path)
    },
    updateHtmlLang() {
      let htmlElem = document.querySelector('html')
      let localeSplit = this.currentLocale.split('_')
      htmlElem.lang = localeSplit[0].toLowerCase()
    },
    updateHtmlTitle() {
      document.title = this.$t('page.title')
    },
    updatePageMetaDescriptions() {
      const metaTags = document.head.querySelectorAll(
        'meta[name=description], meta[property="og:description"]'
      )
      for (const metaTag of metaTags) {
        metaTag.content = this.$t('page.description')
      }
    },
    updateFsDataIfAppDataLoaded(to) {
      this.enteringToRoute = true
      this.fsFataLoadedEventSent = false

      if (!this.appDataLoaded) {
        return
      }

      fsData.splice(0, fsData.length)
      this.initializeFsData()
      this.updateFsData(to)
      this.fsFataLoadedEventSent = true
    },
    initializeFsData() {
      /* global fsData */
      if (!fsData[0]) {
        fsData.push({})
      }
      fsData[0].page = {
        pageInfo: {},
        category: {}
      }
      fsData[0].event = []
      fsData[0].user = [
        {
          licenses: {
            FSECURE: {},
            FREEDOME: {},
            KEY: {}
          }
        }
      ]
    },
    updateFsDataPageName(pageName) {
      /* global fsData */
      let page = fsData[0].page
      page.pageInfo.pageName = pageName
      this.emitFsDataUpdate()
    },
    updateFsData(to, noEmit) {
      /* global fsData */
      let page = fsData[0].page
      let localeSplit = this.currentLocale.split('_')
      page.pageInfo.geoRegion = (this.geoRegion || '').toLowerCase()
      page.pageInfo.language = localeSplit[0].toLowerCase()
      fsData[0].event = [] // clear events

      let tmpPageName = pageNameMap[to] || ''

      if (!tmpPageName) {
        if (to.match('/register')) {
          tmpPageName = 'register'
        } else {
          let pathSplit = to.split('/')
          tmpPageName = pathSplit[pathSplit.length - 1]
          if (
            this.productNames.key === 'ID PROTECTION' &&
            tmpPageName === 'key'
          ) {
            tmpPageName = 'id-protection'
          }
        }
      }

      if (to === '/portal/product/upgrade') {
        if (this.isRenewCart) {
          if (this.isPrepaid) {
            tmpPageName = 'prepaid-renewal-upgrade'
          } else {
            tmpPageName = 'renewal-upgrade'
          }
        }

        if (this.isBuyMoreCart) {
          tmpPageName = 'buy-more'
        }
      }

      if (this.$route.name === '404') {
        tmpPageName = '404'
      }

      page.pageInfo.pageName = tmpPageName
      page.category.subCategory1 = (this.subCategory || '').toLowerCase()

      if (to.match('/activate/direct-purchase/')) {
        page.category.subCategory1 = 'activate'
        page.pageInfo.pageName = 'direct-purchase'
      }

      let tmpSubCategory = pageNameMap[to] || ''
      let pathSplit = to.split('/')
      if (pathSplit.length > 2 && tmpPageName === 'register') {
        if (!page.category.subCategory1.value) {
          tmpSubCategory = pathSplit[2]
          page.category.subCategory1 = tmpSubCategory
        }
      }
      page.category.primaryCategory = 'my-f-secure'

      let user = fsData[0].user[0]
      user.payment = this.contractType
        ? this.contractType.toLowerCase()
        : undefined
      let tmpSubscriptionType = this.isAutomaticSubscription
        ? 'continuous'
        : 'fixed-term'
      if (!this.customerIsLoggedIn) {
        tmpSubscriptionType = undefined
      }
      user.subscriptionType = tmpSubscriptionType
      user.segment = this.promotionId

      for (const additionalServiceEntry of Object.entries(
        this.additionalServices
      )) {
        const additionalService = additionalServiceEntry[1]
        user.licenses[additionalService.name] = { used: true }
      }

      if (!noEmit) {
        this.$nextTick(() => {
          this.emitFsDataUpdate()
        })
      }
    },
    emitFsDataUpdate() {
      this.$root.$emit('fsdata-loaded')
      document.dispatchEvent(new CustomEvent('fsdata-loaded'))
      fsData.push(Object.assign({}, fsData[0], { event: 'fsdata-loaded' }))
    },
    triggerAnalyticsEvents() {
      if (!this.customerIsCompleteAndLoggedIn) {
        return
      }
      for (const eventActionEntry of Object.entries(this.analyticsEvents)) {
        const eventAction = eventActionEntry[1]
        setTimeout(() => {
          fsData[0].event.push({
            eventInfo: {
              eventAction
            }
          })

          /* global fsData */
          let eventPastForm =
            eventAction === 'registration' ? 'registered' : 'logged-in'

          this.$root.$emit(`fsdata-user-just-${eventPastForm}`)
          document.dispatchEvent(
            new CustomEvent(`fsdata-user-just-${eventPastForm}`)
          )
          fsData.push({ event: eventAction })
        }, 1000)
      }
      this.analyticsEvents.splice(0)
    },
    updateHrefLangPaths(to) {
      const links = document.querySelectorAll('link[rel=alternate]')
      const origin = document.location.origin
      for (const link of links) {
        link.href = origin + to + link.href.substring(link.href.indexOf('?'))
      }
    },
    updateFsDataLicenses() {
      /* global fsData */

      if (!fsData[0].user) {
        setTimeout(
          function() {
            this.updateFsDataLicenses()
          }.bind(this),
          6000
        )
        return
      }

      let user = fsData[0].user[0]
      user.licenses.FSECURE.totalDevices = this.safeTotalLicenses + ''
      user.licenses.FSECURE.availableDevices = this.safeAvailableLicenses + ''
      user.licenses.FSECURE.usedDevices = this.safeUsedLicenses + ''

      user.licenses.FREEDOME.totalDevices = this.freedomeTotalLicenses + ''
      user.licenses.FREEDOME.availableDevices =
        this.freedomeAvailableLicenses + ''
      user.licenses.FREEDOME.usedDevices = this.freedomeUsedLicenses + ''

      user.licenses.KEY.totalDevices = this.keyTotalLicenses + ''
      user.licenses.KEY.availableDevices = this.keyAvailableLicenses + ''
      user.licenses.KEY.usedDevices = this.keyUsedLicenses + ''

      setTimeout(() => {
        this.$root.$emit('fsdata-licenses-loaded')
        document.dispatchEvent(new CustomEvent('fsdata-licenses-loaded'))
        fsData.push(
          Object.assign({}, fsData[0], { event: 'fsdata-licenses-loaded' })
        )
      }, 200)
    },
    saveUrlLocaleToCookie() {
      if (!this.$route.query.locale) {
        return
      }

      let locale = this.$route.query.locale
      let isShort = locale.length === 2 && locale.match(/^[a-zA-Z]{2}$/)
      let availableLocales = this.$store.state.ui.availableLocales
      let savedLocale, tmpLocale, shortMatchRegExp

      if (isShort) {
        locale = locale.toLowerCase()
        shortMatchRegExp = new RegExp(`^${locale}_[A-Z]{2}$`)
      }

      for (const availableLocaleEntry of Object.entries(availableLocales)) {
        const availableLocale = availableLocaleEntry[1]
        tmpLocale = availableLocale.locale
        if (
          tmpLocale === locale ||
          (isShort && tmpLocale.match(shortMatchRegExp))
        ) {
          this.$cookies.set('myfs_locale', tmpLocale, '1y')
          savedLocale = tmpLocale
          break
        }
      }

      return savedLocale
    },
    updateCartAndHistory() {
      if (this.appDataLoaded && !this.isSubUser && !this.isIncomplete) {
        this.$store.dispatch('customer/fetchBillingHistory')
        this.$store.dispatch('ui/fetchCart')
      }
    },
    getNextUrl() {
      const nextUrl = this.$cookies.get('myfs_next_url')
      let next = null

      if (nextUrl) {
        next = decodeURIComponent(nextUrl) // Check next from cookies
      } else if (this.$store.state.ui.nextUrl) {
        next = this.$store.state.ui.nextUrl // Check next from store
        this.$store.commit('ui/SET_NEXT_URL', null)
      } else if (this.$route.query.next) {
        next = this.$route.query.next // Check next from query
      }

      return next
    },
    navigateToSenseCartByNextIfCartLoaded() {
      if (!this.isCartLoaded) {
        return
      }

      let next = this.getNextUrl()
      const purchaseType = CartService.getDirectCartUrlType(next)

      if (purchaseType && purchaseType === 'sense') {
        if (this.sensePurchaseUrl) {
          window.location = this.sensePurchaseUrl
          return true
        }
      }

      if (this.waitForRedirect && this.isCartLoaded) {
        this.$store.commit('ui/SET_WAIT_FOR_REDIRECT', false)
      }

      return false
    },
    navigateToNext() {
      let next = this.getNextUrl()

      if (!this.customerIsCompleteAndLoggedIn) {
        this.$store.commit('ui/SET_WAIT_FOR_REDIRECT', false) // If not logged in don't wait for redirect
        return false
      }

      if (CartService.isUrlToCart(next)) {
        let urlSplit = next.split('?')
        let urlParams = urlSplit && urlSplit[1] ? urlSplit[1] : ''
        const purchaseType = CartService.getDirectCartUrlType(next)

        if (purchaseType && purchaseType !== 'sense' && this.directCartUrl) {
          this.removeNextCookie() // Remove cookie if redirected
          window.location = this.directCartUrl
          return
        } else if (purchaseType && purchaseType === 'sense') {
          if (this.sensePurchaseUrl) {
            window.location = this.sensePurchaseUrl
          }

          return
        } else if (
          purchaseType &&
          purchaseType === 'upgrade-to-total' &&
          this.isUpgradeAllowed
        ) {
          next = '/cart/upgrade-to-total' + urlParams
        } else if (
          purchaseType &&
          purchaseType !== 'sense' &&
          this.isUpgradeAllowed
        ) {
          next = '/portal/product/upgrade' + urlParams
        }
      }

      if (next) {
        const newLocation = this.$router.resolve(next)
        if (newLocation.resolved.name !== '404') {
          this.$router.push(newLocation.resolved.fullPath)
          return true // Internal redirect only view update
        }
        if (this.customerIsCompleteAndLoggedIn) {
          this.removeNextCookie() // Remove next cookie also if tried to resolve and logged in already
        }
      }

      return false // No redirect done
    },
    addFsDataLoginEvent() {
      let pathBeforeLogin = this.$cookies.get('myfs_login_from_path')

      if (pathBeforeLogin) {
        this.$cookies.remove('myfs_login_from_path')
      }

      let eventAction =
        pathBeforeLogin && pathBeforeLogin.match(/^\/register/)
          ? 'registration'
          : 'login'
      this.analyticsEvents.push(eventAction)
    },
    saveNextCookie() {
      const next = this.$route.query.next
      if (next) {
        const decodedNext = decodeURIComponent(next)
        if (decodedNext.match(/^\/[\w/-_?%-]+\/?$/)) {
          this.$cookies.set('myfs_next_url', decodedNext, 0)
        }
      }
    },
    removeNextCookie() {
      if (this.$cookies.isKey('myfs_next_url')) {
        this.$cookies.remove('myfs_next_url')
      }
    },
    setLanguageByNavigator() {
      const navigatorLanguage = window.navigator.language
      let modifiedNavigatorLanguage = navigatorLanguage.replace('-', '_')

      if (modifiedNavigatorLanguage.length === 2) {
        modifiedNavigatorLanguage =
          modifiedNavigatorLanguage.toLowerCase() +
          '_' +
          modifiedNavigatorLanguage.toUpperCase()
      }

      this.setLanguage(modifiedNavigatorLanguage)
    },
    setLanguageFromCookies() {
      let locale = this.$cookies.get('myfs_locale')

      if (locale) {
        this.setLanguage(locale)
        return true
      }

      return false
    },
    setLanguageFromLocale(availableLocale) {
      this.$store.commit('ui/SET_CURRENT_LOCALE', availableLocale.locale)
      this.$store.commit('ui/SET_CURRENT_LOCALE_NAME', availableLocale.name)
      this.$root.$emit('current-locale-updated')
    },
    setLanguage(locale) {
      let availableLocales = this.$store.state.ui.availableLocales

      for (const availableLocaleEntry of Object.entries(availableLocales)) {
        const availableLocale = availableLocaleEntry[1]
        if (availableLocale.locale === locale) {
          const lang = locale.split('_')[0]

          if (this.$i18n.locale === lang) {
            this.setLanguageFromLocale(availableLocale)
            return
          }

          this.getAsyncLanguage(lang).then(response => {
            if (response) {
              this.$i18n.setLocaleMessage(lang, response.default)
            }
            this.$i18n.locale = lang
            this.$store.commit('ui/ADD_LOADED_LANGUAGE', lang)
            this.setLanguageFromLocale(availableLocale)
          })
        }
      }
    },
    getAsyncLanguage(lang) {
      if (this.loadedLanguages.includes(lang)) {
        return Promise.resolve()
      }

      return import(
        /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`
      )
    },
    onWindowLoad() {
      this.windowLoaded = true
    },
    onPopState() {
      if (this.windowLoaded) {
        this.$root.$emit('state_pop_window_loaded')
      }
    },
    clearPreviousLicenseCookies() {
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      let urlParts = baseUrl.split('.')
      let cookieDomain =
        '.' +
        urlParts[urlParts.length - 2] +
        '.' +
        urlParts[urlParts.length - 1]
      let expiry = 'Thu, 01 Jan 1970 00:00:00 UTC'
      document.cookie = `sa_lic_info=; expires=${expiry}; path=/;domain=${cookieDomain}`
      document.cookie = `sa_priv_lic_info=; expires=${expiry}; path=/;domain=${cookieDomain}`
      document.cookie = `sa_pwd_lic_info=; expires=${expiry}; path=/;domain=${cookieDomain}`
    },
    launchCookiePoll() {
      let previousCookies = {}
      this.emitCookieUpdate(previousCookies, true)
      return CookieService.launchCookiePoll(1000, () => {
        this.emitCookieUpdate(previousCookies)
      })
    },
    emitCookieUpdate(previousCookies, forceEmit) {
      let allCookies = CookieService.getAll()
      for (const [key, value] of Object.entries(allCookies)) {
        if (previousCookies[key] !== value || forceEmit) {
          this.$root.$emit('cookie_updated', key, value)
        }
      }
      previousCookies = allCookies
    },
    emitLogIn() {
      const notLoggedPrev = this.$cookies.get('myfs_not_logged_in')

      if (notLoggedPrev === '1' && this.customerIsLoggedIn) {
        this.$nextTick(() => {
          this.$root.$emit('just-logged-in')
          this.runReminderPopup = true
          this.$root.$emit('login-check-after')
        })

        this.$cookies.remove('myfs_not_logged_in')
        return
      }

      this.$root.$emit('login-check-after')
    },
    saveBeforeLoginPathToCookie() {
      let path = this.$route.path

      if (path === '/' || !this.appDataLoaded) {
        return
      }

      this.$cookies.set('myfs_login_from_path', path, 0)
    },
    runIfNotLoggedIn() {
      if (this.customerIsLoggedIn) {
        return
      }
      this.saveBeforeLoginPathToCookie()
    },
    runAfterDataLoaded() {
      // check if safe avenue is having maintenance //
      if (this.maintenanceSafeAvenue === true) {
        this.$router.push('/down_for_maintenance')
      }

      this.runIfNotLoggedIn()

      if (!this.fsFataLoadedEventSent && this.enteringToRoute) {
        this.updateFsData(this.$route.path)
      }

      this.enteringToRoute = false
      this.emitLogIn()
    },
    updateLicenseDataFromCookies(cookieName, cookieValue) {
      if (cookieName === 'sa_lic_info') {
        let licenseData = JSON.parse(decodeURIComponent(cookieValue))
        this.$store.commit(
          'customer/licenses/SET_SAFE_TOTAL_LICENSES',
          parseInt(licenseData.total)
        )
        this.$store.commit(
          'customer/licenses/SET_SAFE_AVAILABLE_LICENSES',
          parseInt(licenseData.available)
        )
      }

      if (cookieName === 'sa_priv_lic_info') {
        let licenseData = JSON.parse(decodeURIComponent(cookieValue))
        this.$store.commit(
          'customer/licenses/SET_FREEDOME_TOTAL_LICENSES',
          parseInt(licenseData.total)
        )
        this.$store.commit(
          'customer/licenses/SET_FREEDOME_AVAILABLE_LICENSES',
          parseInt(licenseData.available)
        )
      }

      if (cookieName === 'sa_pwd_lic_info') {
        let licenseData = JSON.parse(decodeURIComponent(cookieValue))
        this.$store.commit(
          'customer/licenses/SET_KEY_TOTAL_LICENSES',
          parseInt(licenseData.total)
        )
        this.$store.commit(
          'customer/licenses/SET_KEY_AVAILABLE_LICENSES',
          parseInt(licenseData.available)
        )
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';
@import '@/scss/global.scss';

.myfs-portal {
  font-family: 'FSecureOffice';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
</style>
