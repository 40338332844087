import polyfill from './polyfill'
import Vue from 'vue'
import 'whatwg-fetch'
import router from './router'
import store from './store/store'
import i18n from './i18n'
import './cookies'
import Vuelidate from 'vuelidate'
import VueRouterMultiView from 'vue-router-multi-view'
//  import './registerServiceWorker'
import MyfsPortal from './components/TheMyfsPortal.vue'
polyfill()

init()

async function init() {
  polyfill()
  Vue.use(Vuelidate)
  Vue.use(VueRouterMultiView)
  Vue.config.productionTip = false
  new Vue({
    router,
    store,
    i18n,
    render: h => h(MyfsPortal)
  }).$mount('#app')
}
