import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import VueCookies from 'vue-cookies'
import LogoutService from '@/services/LogoutService'
import CartService from '@/services/CartService'
import CustomerService from '@/services/CustomerService'
import PrerendererService from '@/services/PrerendererService'
import TokenLoginService from './services/TokenLoginService'

const IndexView = () =>
  import(/* webpackChunkName: "index-view" */ './views/IndexView.vue')
const ProductView = () =>
  import(/* webpackChunkName: "views" */ './views/ProductView.vue')
const LoginView = () =>
  import(/* webpackChunkName: "login-view" */ './views/LoginView.vue')
const RegisterView = () =>
  import(/* webpackChunkName: "register-view" */ './views/RegisterView.vue')
const AccountView = () =>
  import(/* webpackChunkName: "views" */ './views/AccountView.vue')
const AccountUpdateView = () =>
  import(/* webpackChunkName: "views" */ './views/AccountUpdateView.vue')
const CouponRenewView = () =>
  import(/* webpackChunkName: "views" */ './views/CouponRenewView.vue')
const CouponRedeemView = () =>
  import(/* webpackChunkName: "views" */ './views/CouponRedeemView.vue')
const DeleteUserConfirmationView = () =>
  import(
    /* webpackChunkName: "views" */ './views/DeleteUserConfirmationView.vue'
  )
const AutorenewCancelView = () =>
  import(/* webpackChunkName: "views" */ './views/AutorenewCancelView.vue')
const AutorenewEnableView = () =>
  import(/* webpackChunkName: "views" */ './views/AutorenewEnableView.vue')
const AutorenewEnableReasonView = () =>
  import(
    /* webpackChunkName: "views" */ './views/AutorenewEnableReasonView.vue'
  )
const ConvertToAnnualView = () =>
  import(/* webpackChunkName: "views" */ './views/ConvertToAnnualView.vue')
const SubscriptionView = () =>
  import(/* webpackChunkName: "views" */ './views/SubscriptionView.vue')
const NotFoundView = () =>
  import(/* webpackChunkName: "not-found-view" */ './views/NotFoundView.vue')
const NotAvailableInCountryView = () =>
  import(
    /* webpackChunkName: "not-available-in-country-view" */ './views/NotAvailableInCountryView.vue'
  )
const UpgradeView = () =>
  import(/* webpackChunkName: "views" */ './views/UpgradeView.vue')
const CouponShareView = () =>
  import(/* webpackChunkName: "views" */ './views/CouponShareView.vue')
const DownForMaintenanceView = () =>
  import(/* webpackChunkName: "views" */ './views/DownForMaintenanceView.vue')
const TokenLoginErrorView = () =>
  import(/* webpackChunkName: "views" */ './views/TokenLoginErrorView.vue')
const RegisterLoginView = () =>
  import(/* webpackChunkName: "views" */ './views/RegisterLoginView.vue')
const IncompleteRegistrationView = () =>
  import(
    /* webpackChunkName: "views" */ './views/IncompleteRegistrationView.vue'
  )
const StartTrialView = () =>
  import(/* webpackChunkName: "views" */ './views/StartTrialView.vue')
const CouponApplySuccessView = () =>
  import(/* webpackChunkName: "views" */ './views/CouponApplySuccessView')

Vue.use(Router)

/* global decodeURIComponent */
const decode = decodeURIComponent

/**
  Functionality from https://github.com/vuejs/vue-router/blob/v3.1.5/dist/vue-router.esm.js#L227
  Modified to replace + with %2B instead of space
 */
function parseQuery(query) {
  var res = {}

  query = query.trim().replace(/^(\?|#|&)/, '')

  if (!query) {
    return res
  }

  query.split('&').forEach(function(param) {
    var parts = param.replace(/\+/g, '%2B').split('=')
    var key = decode(parts.shift())
    var val = parts.length > 0 ? decode(parts.join('=')) : null

    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })

  return res
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/portal/activate_additional_service/:serviceId',
      beforeEnter: (to, from, next) => {
        if (!store.state.customer.isLoggedIn) {
          next()
        }

        window.location =
          '/portal/order?activate_service=' + parseInt(to.params.serviceId)
      }
    },
    {
      path: '/',
      name: 'index-view',
      component: IndexView,
      beforeEnter: (to, from, next) => {
        const isSenseSubscription =
          store.getters['customer/contract/isSenseSubscription']
        let isLoggedIn = store.state.customer.isLoggedIn
        const destinationPath = isSenseSubscription
          ? '/portal/order'
          : '/portal/welcome'
        if (isLoggedIn) {
          if (from.path === destinationPath) {
            next(vm => {
              vm.$router.go(0)
            })
            return
          }
          next(destinationPath)
        } else {
          if (from.path === '/login') {
            next(vm => {
              vm.$router.go(0)
            })
          }
        }
      }
    },
    {
      path: '/portal/contact',
      name: 'account-view',
      component: AccountView
    },
    {
      path: '/portal/contact/update',
      name: 'account-update-view',
      component: AccountUpdateView
    },
    {
      path: '/portal/delete-user/confirmation',
      name: 'delete-user-confirmation-view',
      component: DeleteUserConfirmationView
    },
    {
      path: '/portal/cancel_autorenew',
      name: 'autorenew-cancel-view',
      component: AutorenewCancelView
    },
    {
      path: '/portal/enable_autorenew',
      name: 'autorenew-enable-view',
      component: AutorenewEnableView
    },
    {
      path: '/portal/convert_to_annual',
      name: 'convert-to-annual-view',
      component: ConvertToAnnualView
    },
    {
      path: '/portal/enable_autorenew_reason',
      name: 'autorenew-enable-reason-view',
      component: AutorenewEnableReasonView
    },
    {
      path: '/portal/coupon/renew',
      name: 'coupon-renew-view',
      component: CouponRenewView
    },
    {
      path: '/portal/coupon/redeem',
      name: 'coupon-redeem-view',
      component: CouponRedeemView
    },
    {
      path: '/portal/complete-registration',
      name: 'incomplete-registration-view',
      component: IncompleteRegistrationView
    },
    {
      path: '/portal/start-trial',
      name: 'start-trial-view',
      component: StartTrialView
    },
    {
      path: '/portal/coupon/success',
      name: 'coupon-apply-success-view',
      component: CouponApplySuccessView
    },
    {
      path: '/portal/product/upgrade',
      name: 'product-upgrade-view',
      component: UpgradeView
    },
    {
      path: '/portal/order',
      name: 'subscription-view',
      component: SubscriptionView
    },
    {
      path: '/login',
      name: 'login-view',
      component: LoginView
    },
    {
      path: '/cart',
      name: 'cart-direct',
      beforeEnter: (to, from, next) => {
        let locale = VueCookies.get('myfs_locale')
        store.dispatch('ui/fetchCart', locale).then(result => {
          if (!result || result.errorCode) {
            next('/')
          }

          if (result['direct_to_cart']) {
            window.location = result['direct_to_cart']
            next()
          } else {
            next('/portal/product/upgrade')
          }
        })
      }
    },
    {
      path: '/cart/upgrade-to-total',
      name: 'cart-direct-upgrade-to-total',
      beforeEnter: (to, from, next) => {
        let locale = VueCookies.get('myfs_locale')
        store.dispatch('ui/fetchCart', locale).then(result => {
          if (!result || result.errorCode) {
            next('/')
          }
          // in case of misuse of this route when it should directly brings user to Cleverbridge
          if (result['direct_to_cart']) {
            window.location = result['direct_to_cart']
            next()
          } else {
            store.commit(
              'ui/SET_IS_SAFE_DEFAULT_UPGRADE_POPUP_SELECTION',
              false
            )
            next('/portal/product/upgrade')
          }
        })
      }
    },
    {
      path: '/login/direct/:token?',
      name: 'login-direct',
      beforeEnter: (to, from, next) => {
        let token = to.params.token
        TokenLoginService.postLoginWithToken(token).then(response => {
          if (!response) {
            next({
              path: '/login/token/error',
              replace: true
            })
          } else {
            window.location.replace('/')
          }
        })
      }
    },
    {
      path: '/login/token/error',
      name: 'token-login-error-view',
      component: TokenLoginErrorView
    },
    {
      path: '/register/:promotion?/:voucher?',
      name: 'register-view',
      alias: '/activate/direct-purchase/:dphash?',
      component: RegisterView
    },
    {
      path: '/portal/(welcome|freedome|key|idp-portal)',
      name: 'product-view',
      component: ProductView
    },
    {
      path: '/portal/coupon/share/:product?',
      name: 'coupon-share-view',
      component: CouponShareView
    },
    {
      path: '/logout',
      name: 'logout-view',
      beforeEnter: (to, from, next) => {
        try {
          const token = store.state.customer.token
          const tmpPromise = !token
            ? CustomerService.fetchCustomerData()
            : Promise.resolve({ errorCode: null, token })
          tmpPromise.then(tmpResponse => {
            LogoutService.postLogoutUser(tmpResponse.token).then(response => {
              store.commit('customer/SET_IS_LOGGED_IN', false)
              let identifier = store.state.customer.identifier
              VueCookies.remove('sa_lic_info')
              VueCookies.remove('sa_priv_lic_info')
              VueCookies.remove('sa_pwd_lic_info')
              VueCookies.remove(identifier + '_days_until_expiration')
              VueCookies.remove(identifier + '_is_trial')
              VueCookies.remove(identifier + '_product')
              VueCookies.remove(identifier + '_license_size')

              if (!response) {
                next({
                  path: '/login',
                  replace: true
                })
                return
              }

              let redirecUrl =
                'https://' + document.location.hostname + '/login'
              window.location =
                response + '?redirect_url=' + encodeURIComponent(redirecUrl)
            })
          })
        } catch (err) {
          console.log(err)
          next('/')
        }
      }
    },
    {
      path: '/not_available_in_country',
      name: 'not-available-in-country-view',
      component: NotAvailableInCountryView
    },
    {
      path: '/down_for_maintenance',
      name: 'down-for-maintenance-view',
      component: DownForMaintenanceView
    },
    {
      path: '/registration-login',
      name: 'registration-login-view',
      component: RegisterLoginView
    },
    {
      path: '*',
      name: '404',
      component: NotFoundView
    }
  ],
  parseQuery: parseQuery
})

let redirectedByNext = false
let fetchingCustomer = false
const viewsForSubUser = {
  'product-view': true,
  'account-view': true,
  'account-update': true,
  'account-update-view': true,
  'logout-view': true
}
router.beforeEach((to, from, next) => {
  if (
    (redirectedByNext && to.name !== 'index-view') ||
    to.name === 'logout-view'
  ) {
    redirectedByNext = false
    next()
    return
  }

  let nextCookieVal = VueCookies.get('myfs_next_url')
  let nextUrl = null
  let isNextUrlFromCookie = false
  const remoteDataLoaded = store.state.customer.remoteDataLoaded
  const isPrerenderBrowser = PrerendererService.isPrerendererBrowser()

  if (nextCookieVal) {
    nextUrl = decodeURIComponent(VueCookies.get('myfs_next_url'))
    isNextUrlFromCookie = true
  }

  if (!nextUrl && to.query.next) {
    nextUrl = to.query.next
  }

  if (
    !remoteDataLoaded &&
    isNextUrlFromCookie &&
    (to.name !== 'login-view' && // No need to pre fetch cart on login or register since most likely not logged in
      to.name !== 'register-view') &&
    nextUrl &&
    CartService.isUrlToCart(nextUrl)
  ) {
    store.commit('ui/SET_NEXT_URL', nextUrl)
    store.commit('ui/SET_WAIT_FOR_REDIRECT', true)

    // Speed up cart fetch if next url exists
    store.dispatch('ui/fetchCart')
  }

  if (!fetchingCustomer && !isPrerenderBrowser) {
    fetchingCustomer = true
    store
      .dispatch('customer/fetch')
      .then(response => {
        const pathName = to.path
        const viewName = to.name
        const query = to.query
        const isLoggedIn = store.state.customer.isLoggedIn
        const isInComplete = store.getters['customer/isIncomplete']
        const isSubUser =
          store.getters['customer/contract/isSubUser'] && !isInComplete
        const isSenseSubscription =
          store.getters['customer/contract/isSenseSubscription']
        let destinationPath = isSenseSubscription
          ? '/portal/order'
          : '/portal/welcome'
        if (response && response.errorCode === 'country_not_allowed') {
          redirectedByNext = true
          next({ path: '/not_available_in_country', replace: true })
          return
        }
        if (
          response &&
          viewName === 'not-available-in-country-view' &&
          response.errorCode !== 'country_not_allowed'
        ) {
          redirectedByNext = true
          next({ path: '/login', replace: true })
          return
        }
        if (store.state.ui.waitForRedirect) {
          destinationPath = '/'
        }
        if (viewName === 'down-for-maintenance-view') {
          redirectedByNext = true
          let tmpRedirect = '/login'
          if (isLoggedIn) {
            tmpRedirect = destinationPath
          }
          next({ path: tmpRedirect, replace: true, query })
        }
        if (!isLoggedIn) {
          VueCookies.set('myfs_not_logged_in', 1, 0)
        }
        if (viewName === 'token-login-error-view' && !isLoggedIn) {
          redirectedByNext = true
          next({ path: '/login/token/error', replace: true, query })
        } else if (
          viewName !== 'login-view' &&
          viewName !== 'login-direct' &&
          viewName !== 'register-view' &&
          viewName !== 'down-for-maintenance-view' &&
          viewName !== '404' &&
          viewName !== 'not-available-in-country-view' &&
          viewName !== 'registration-login-view' &&
          !isLoggedIn
        ) {
          redirectedByNext = true
          next({ path: '/login', replace: true, query })
        }
        if (pathName === '/' && isLoggedIn) {
          redirectedByNext = true
          next({ path: destinationPath, replace: true, query })
        }
        if (viewName === 'login-view' && isLoggedIn) {
          redirectedByNext = true
          next({ path: destinationPath, replace: true, query })
        }
        if (viewName === 'register-view' && isLoggedIn) {
          redirectedByNext = true
          next({ path: destinationPath, replace: true, query })
        }
        if (viewName === 'registration-login-view' && isLoggedIn) {
          redirectedByNext = true
          next({ path: destinationPath, replace: true, query })
        }
        if (!viewsForSubUser[viewName] && isLoggedIn && isSubUser) {
          redirectedByNext = true
          next({ path: destinationPath, replace: true, query })
        }
        if (
          isInComplete &&
          viewName !== 'coupon-redeem-view' &&
          viewName !== 'complete-registration-view' &&
          viewName !== 'start-trial-view'
        ) {
          redirectedByNext = true
          destinationPath = '/portal/complete-registration'
          next({ path: destinationPath, replace: true, query })
        }
        if (!remoteDataLoaded) {
          if (
            isLoggedIn &&
            !isInComplete &&
            !isSubUser &&
            !isSenseSubscription
          ) {
            store.dispatch('customer/fetchBillingHistory')
            store.dispatch('ui/fetchCart')
            store.dispatch('ui/fetchCustomizations')
            store.dispatch('ui/fetchCouponShareProductNames')
            store.dispatch('customer/fetchEnableAutoRenewOptions')
            store.dispatch('customer/fetchConvertToAnnualOptions')
          }

          next()
        }
        fetchingCustomer = false
      })
      .catch(err => {
        const errorType = typeof err
        if (errorType === 'number') {
          store.commit('customer/SET_IS_LOGGED_IN', false)
          store.commit('customer/SET_REMOTE_DATA_LOADED', true)
          next('/down_for_maintenance')
        }

        fetchingCustomer = false
      })
  }

  if (remoteDataLoaded) {
    next()
  }
})

export default router
